import { FestivalSalesOffer } from '~/types/festival'

import { GetVoucherCopy } from '..'

import { getWRCopy } from './WR'

export type VoucherOrderCopy = {
  title: string
  section: {
    title: string
    date: string
    guestCount: (peopleCount: number) => string
  }
  summary: {
    title: string
    target: string
    reservationFee: string
    total: string
  }
}

// /voucher-order page
export const getVoucherOrderCopy: GetVoucherCopy<VoucherOrderCopy> = festivalSalesOffer => {
  switch (festivalSalesOffer) {
    case FestivalSalesOffer.WR:
    default:
      return getWRCopy
  }
}
