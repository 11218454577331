import { EventsListingRoutes, GiftEventsListingBanners } from '~/config/clubConfig/types'
import { FestivalSalesOffer } from '~/types/festival'

import { VoucherImagesFunction } from '.'

const SideEventsListing = {
  mainBanner: {
    desktop: 'side-events-listing/banner.png',
    mobile: 'side-events-listing/banner-mobile.png',
  },
}

export const getVoucherEventsListingBanners: VoucherImagesFunction<GiftEventsListingBanners> = festivalSalesOffer => {
  switch (festivalSalesOffer) {
    case FestivalSalesOffer.WR:
    default:
      return {
        [EventsListingRoutes.GiftEventsListing]: {
          mainBanner: {
            desktop: 'gift-events-listing/WR_banner_desktop-cz.png',
            mobile: 'gift-events-listing/WR_banner_mobile-cz.png',
          },
        },
        [EventsListingRoutes.SideEventsListing]: SideEventsListing,
      }
  }
}
