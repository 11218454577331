import { ClubConfig, Logos } from '~/config/clubConfig/types'
import { FestivalCodesEnum } from '~/generated/graphql'
import { FestivalSalesOffer } from '~/types/festival'

import { assets } from './assets'
import { copy } from './copy'

const logos: Logos = {
  [FestivalCodesEnum.Rw]: {
    centered: '/assets/logo/logo-club.svg',
    left: '/assets/logo/logo-club.svg',
  },
  [FestivalCodesEnum.Bw]: {
    centered: '/assets/logo/logo-festival-bw.svg',
    left: '/assets/logo/logo-festival-bw.svg',
  },
  [FestivalCodesEnum.Fdw]: {
    centered: '/assets/logo/logo-festival-fdw.sv',
    left: '/assets/logo/logo-festival-fdw.svg',
  },
  club: {
    centered: '/assets/logo/logo-club.svg',
    left: '/assets/logo/logo-club.svg',
  },
}

export const config: ClubConfig = {
  aboutPageEnabled: true,
  assets,
  blikEnabled: true,
  blogEnabled: true,
  careerPageEnabled: true,
  chmEnabled: true,
  copy,
  customRwSuccessCopy: true,
  d2dEnabled: true,
  defaultGeolocation: {
    latitude: 52.229675,
    longitude: 21.01223,
  },
  defaultRegion: {
    id: '5',
    name: 'Warszawa',
  },
  directCardPaymentEnabled: true,
  enabledLanguages: {
    en: 'English',
    pl: 'Polish',
    uk: 'Ukrainian',
  },
  extraMarketingConsentEnabled: true,
  festivalSalesOffer: FestivalSalesOffer.XMAS,
  footerAwardsEnabled: true,
  klarnaEnabled: false,
  logos: logos,
  otherFestivalsEnabled: true,
  paypoEnabled: true,
  redirectHeroToLandingEnabled: false,
  restaurantsListingEnabled: true,
  showFooterCopyright: true,
  sideEventListingPageEnabled: true,
  useRWInsteadOfRC: false,
  visaMobileEnabled: true,
}

export default config
