// @ts-strict-ignore
import { useEffect, useState } from 'react'

import { getCookie } from '~/utils/cookie'

import ConfirmLegalAgeModal, { confirmLegalAgeModalProps } from '~/components/User/ConfirmLegalAgeModal'
import { ClubConfig, getClubConfig } from '~/config/clubConfig'
import { COOKIE, pathFragments, paths } from '~/constants'
import { FestivalCodesEnum } from '~/generated/graphql'
import { FestivalListingHeaderTopBar } from '~/layout/FestivalListing/FestivalListingHeaderTopBar'
import { landingPageRoutes } from '~/middleware'
import { FestivalSalesOffer } from '~/types/festival'

import { useModal } from '../Modal'
import { ModalProps } from '../Modal/ModalTypes'

import TopBarMenu from './TopBarMenu'

export const modalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: true,
  closeIconPosition: 'outside',
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed',
  size: 'big',
  iconSize: 'big',
}

export const searchModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: true,
  closeIconPosition: 'inside',
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed',
  size: 'small',
  iconSize: 'big',
  closeOnEscapeKeyClick: true,
}

export const menuModalProps: ModalProps = {
  hasVisibleOverlay: true,
  hasClickableOverlay: false,
  closeIconPosition: 'outside',
  fillScreenOnMobile: true,
  handleClick: () => null,
  position: 'fixed',
  size: 'small',
  iconSize: 'big',
  closeOnEscapeKeyClick: true,
}

export type Tab = {
  label: [string, string]
  href: string
  matchingUrlFragments: string[]
  excludedUrlFragments?: string[]
  enabledForFeatureFlag?: keyof ClubConfig
}

export const TABS: Tab[] = [
  {
    label: ['Header', 'Find a table'],
    href: paths.restaurants,
    matchingUrlFragments: [pathFragments.restaurants],
    excludedUrlFragments: [pathFragments.festivalRestaurants],
    enabledForFeatureFlag: 'd2dEnabled',
  },
  {
    label: ['Header', '#ChefsMenu'],
    href: paths.chefsMenuOffers,
    matchingUrlFragments: [pathFragments.chefsMenuOffers],
    enabledForFeatureFlag: 'chmEnabled',
  },
  {
    label: ['Header', 'Festivals'],
    href: getClubConfig().festivalSalesOffer === FestivalSalesOffer.WR ? paths.home : paths.festivals,
    matchingUrlFragments: [pathFragments.festivalRestaurants],
  },
  {
    label: ['Header', 'Blog'],
    href: paths.blog,
    matchingUrlFragments: [pathFragments.blogPost],
    enabledForFeatureFlag: 'blogEnabled',
  },
]

export const restaurantWeekHeaderTabs: Tab[] = [
  {
    label: ['FERListingPage', 'Reserve'],
    href: paths.festivalListing('restaurant-week'),
    matchingUrlFragments: [pathFragments.restaurantWeek],
  },
  {
    label: ['FERListingPage', 'Premium Experiences'],
    href: paths.sideEventsListing,
    matchingUrlFragments: [pathFragments.sideEvents],
  },
  {
    label: ['FERListingPage', 'Other Festivals'],
    href: paths.festivals,
    matchingUrlFragments: landingPageRoutes.filter(festival => festival !== 'restaurant-week').map(pathFragments.festival),
  },
  {
    label: ['FERListingPage', 'Blog'],
    href: paths.blog,
    matchingUrlFragments: [pathFragments.blogPost],
  },
]

export const getHeaderTabs = (festivalCode?: string) => {
  switch (festivalCode) {
    case FestivalCodesEnum.Rw:
      return restaurantWeekHeaderTabs
    case FestivalCodesEnum.Bw:
      return restaurantWeekHeaderTabs
    default:
      return TABS
  }
}

export const getHeaderTopbar = ({ festivalCode, onClose }: { festivalCode?: string; onClose: () => void }) => {
  switch (festivalCode) {
    case FestivalCodesEnum.Gw:
      return <FestivalListingHeaderTopBar onClose={onClose} />
    default:
      return <TopBarMenu onClose={onClose} />
  }
}

export const areAnyInPath = (fragments: Array<string> = [], path: string) => {
  const basePath = path.split('?')[0]

  return fragments?.some(fragment => {
    const regex = new RegExp(`(^|/)${fragment}(/|$)`)
    return regex.test(basePath)
  })
}

export const openConfirmLegalAgeModal = (open, onClose) => {
  open(confirmLegalAgeModalProps, <ConfirmLegalAgeModal handleClose={onClose} />)
}

const LOCATION_SELECTOR_VISIBLE_PATHS = [paths.home]

export const isLocationSelectorVisibleOnRoute = (route: string) => LOCATION_SELECTOR_VISIBLE_PATHS.includes(route)

export const useHeaderModals = () => {
  const modal = useModal()
  const [showLocationModal, setShowLocationModal] = useState(false)

  useEffect(() => {
    const cookieConsent = getCookie(null, COOKIE.ALERT_BOX_CLOSED)
    const isAdult = getCookie(null, COOKIE.ADULT)
    const location = getCookie(null, COOKIE.LOCATION)

    if (cookieConsent && !isAdult) {
      openConfirmLegalAgeModal(modal.open, () => {
        !location && setShowLocationModal(true)
        modal.close()
      })
    }

    if (cookieConsent && isAdult && !location) {
      setShowLocationModal(true)
    }

    window &&
      window?.OneTrust &&
      window.OneTrust?.OnConsentChanged(() => {
        const updatedCookieConsent = getCookie(null, COOKIE.ALERT_BOX_CLOSED)
        if (modal && updatedCookieConsent) {
          !isAdult &&
            openConfirmLegalAgeModal(modal.open, () => {
              !location && setShowLocationModal(true)
              modal.close()
            })
          isAdult && !location && setShowLocationModal(true)
        }
      })
  }, [])

  return { modal, showLocationModal }
}
