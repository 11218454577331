import { processEnv } from '~/utils/env'

import { MarketCode } from '~/config/clubConfig'

export default () => {
  const def = {
    name: 'RestaurantClub',
    id: 'rc',
    url: 'https://restaurantclub.pl',
    oldUrl: 'https://festival.restaurantclub.pl',
    query: '',
    fb: 'https://www.facebook.com/RestaurantWeekPolska',
    instagram: 'https://www.instagram.com/explore/tags/restaurantclubpolska',
    linkedin: 'https://www.linkedin.com/company/restaurant-week-polska/mycompany/',
    email: 'info@restaurantclub.pl',
    showAd: true,
    enableAnalytics: true,
    enableFestivalParam: true,
    facebookAppId: processEnv.FACEBOOK_APP_ID,
    appleServiceId: 'pl.restaurantweek.restaurantclub.service.stg',
    apiUrl: processEnv.API_URL || 'https://api.restaurantclub.pl',
    googleAPIKey: processEnv.GOOGLE_API_KEY,
    sentryDsn: processEnv.SENTRY_DSN,
    sentryRelease: processEnv.SENTRY_RELEASE || '',
    sentryEnv: processEnv.NEXT_ENV || 'unknown',
    mapboxToken: processEnv.MAPBOX_TOKEN,
    gtmKey: processEnv.GTM_KEY,
    googleClientId: processEnv.GOOGLE_CLIENT_ID,
    clubUrl: processEnv.CLUB_URL || 'https://restaurantclub.pl',
    marketCode: (processEnv.MARKET_CODE || 'pl') as MarketCode,
    isDemo: processEnv.NEXT_ENV === 'demo',
    enableKlarna: false,
    klarna: {
      sdk: 'https://eu-library.playground.klarnaservices.com/lib.js',
      client_id: '462d7378-512a-5d05-9ddf-2dec50435c7a',
    },
    commonNinjaWidgetIds: processEnv.COMMONNINJA_WIDGETS_IDS ? (processEnv.COMMONNINJA_WIDGETS_IDS?.split(';') as string[]) : [],
  } as const

  const development = {
    ...def,
    oldUrl: 'http://localhost:8000',
  } as const

  const review = {
    ...def,
  } as const

  const staging = {
    ...def,
    oldUrl: 'https://old-stage.rclub.dev',
  } as const
  const demo = {
    ...def,
    appleServiceId: processEnv.APPLE_SERVICE_ID || 'pl.restaurantweek.restaurantclub.service.demo',
  } as const

  const production = {
    ...def,
    enableAnalytics: true,
    appleServiceId: processEnv.APPLE_SERVICE_ID || 'pl.restaurantweek.restaurantclub.service',
  } as const

  const config = {
    development,
    review,
    staging,
    demo,
    production,
  }
  const testing = {
    ...development,
  }

  const env = processEnv.NEXT_ENV as 'development' | 'staging' | 'demo' | 'production'

  return config[env] || testing
}
