import { getClubConfig } from '~/config/clubConfig'
import { FestivalCodesEnum } from '~/generated/graphql'
import { gt as GT } from '~/locale'
import { FestivalSalesOffer } from '~/types/festival'

import { getVoucherSuccessScreenCopy } from './success-screen'
import { getVoucherDetailsCopy } from './voucher-details'
import { getVoucherOrderCopy } from './voucher-order'

type Code = FestivalCodesEnum | string | null | undefined

export type GetFestivalCopy<T> = (gt: typeof GT) => T

export type GeFestivalSalesOfferCopy<T> = (code: Code) => GetFestivalCopy<T>

export type GetVoucherCopy<T> = (festivalSalesOffer: FestivalSalesOffer) => GeFestivalSalesOfferCopy<T>

function getVoucherTranslation<T>(getTranslationObject: GetVoucherCopy<T>) {
  return (gt: typeof GT, code: Code) => {
    const festivalSalesOffer = getClubConfig().festivalSalesOffer
    return getTranslationObject(festivalSalesOffer)(code)(gt)
  }
}

export const voucherTranslation = {
  successScreen: getVoucherTranslation(getVoucherSuccessScreenCopy),
  details: getVoucherTranslation(getVoucherDetailsCopy),
  order: getVoucherTranslation(getVoucherOrderCopy),
}
