import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { mediaQueryDown, mediaQueryUp, mediaQueryUpVal, UniversalContainer } from '~/theme/utils/grid'
import { Body2Link, Heading6 } from '~/theme/utils/typography'

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gray[0]};
  margin: 0 auto;

  ${mediaQueryUp('m')} {
    max-width: 1440px;
  }
`

export const TopBarContainer = styled(UniversalContainer)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray[100]};
`

export const TopBar = styled.div`
  display: flex;
  max-width: 1440px;
  padding: 0.875rem 0;
  color: ${({ theme }) => theme.colors.black[900]};
  width: 100%;
  justify-content: space-between;
  margin: auto;

  ${mediaQueryDown('l')} {
    display: none;
  }
`

export const TopBarLink = styled(Body2Link)`
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: ${({ theme }) => theme.colors.gray[1000]};
  margin: auto;
  height: 5.5rem;

  ${mediaQueryDown('l')} {
    height: 3.75rem;
  }
`

export const Inner = styled.div<{ gap?: string; width?: string }>`
  display: flex;
  align-items: center;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `};

  ${({ gap }) =>
    gap &&
    css`
      & > *:not(:last-child) {
        margin-right: calc(${gap} / 2);
      }

      ${mediaQueryUp('m')} {
        & > *:not(:last-child) {
          margin-right: ${gap};
        }
      }
    `}
`

export const Logo = styled.img`
  display: block;
  height: 1.5rem;
  width: auto;

  ${mediaQueryUp('l')} {
    height: 2rem;
  }
`
export const HeaderLinkWithAnimation = styled(Heading6)<{ isActive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  cursor: pointer;
  text-align: center;
  height: 100%;
  position: relative;

  &:hover {
    &::after {
      transform: scaleY(1);
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: ${({ isActive }) => (isActive ? 'none' : 'scaleY(0)')};
    height: 3px;
    border-radius: 3px 3px 0 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.orange[400]};
    transform-origin: bottom;
    transition:
      transform 0.2s,
      opacity 0.2s;
  }
`

export const HeaderLink = styled(Heading6)<{ isActive?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: ${({ theme }) => theme.text.fontWeight.medium};
  cursor: pointer;
  transition: color 0.2s ease;
  text-align: center;
  height: 100%;
  position: relative;

  img {
    height: 1.5rem;
    min-width: 1.5rem;
    filter: ${({ theme }) => theme.filters.gray[900]};
    ${mediaQueryUp('l')} {
      height: 1.375rem;
      min-width: 1.375rem;
    }
  }

  &::after {
    content: '';
    grid-row: 3;
    position: absolute;
    bottom: 0;
    left: -0.25rem;
    width: calc(100% + 0.5rem);
    height: 3px;
    border-radius: 3px 3px 0 0;
    background-color: ${({ isActive, theme }) => (isActive ? theme.colors.orange[400] : 'transparent')};
    transition: background-color 0.3s;
  }
`
export const TabletNavLinksContainer = styled(Inner)`
  display: none;
  justify-content: flex-end;
  padding: 0;

  ${mediaQueryDown('l')} {
    display: flex;
  }
`

export const Section = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  gap: 2.5rem;
`

export const DesktopNavLinksContainer = styled.div<{ loggedIn?: boolean }>`
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  ${mediaQueryUp('l')} {
    display: flex;
  }

  @media ${mediaQueryUpVal(1220)} {
    margin-right: ${({ loggedIn }) => (loggedIn ? '7rem' : '0')};
    gap: 2rem;
  }

  ${HeaderLink} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
