import dayjs from 'dayjs'
import slugify from 'slugify'

import config from '~/config'
import { getClubConfig } from '~/config/clubConfig'
import { FestivalCodesEnum, SlotDefinition } from '~/generated/graphql'

export const hasWindow = () => typeof window !== 'undefined'

export const getStaticFilePath = (path: string = ''): string => {
  let filePath
  try {
    filePath = require(`../../public/${path}`)
  } catch (error) {
    filePath = ''
  }

  if (typeof filePath === 'object') {
    return filePath?.default?.src || ''
  }

  return filePath
}

export const scrollTo =
  (
    targetId: string,
    configObject: ScrollIntoViewOptions = {
      behavior: 'smooth', // smooth scrolling
      block: 'start', // align to the top
    }
  ) =>
  async (e: MouseEvent | null) => {
    e && e.preventDefault()
    const el = targetId === 'body' ? document.body : document.getElementById(targetId)

    if (el) {
      el.scrollIntoView(configObject)
    }
  }

export const supportsIntersectionObserver = () => 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window

export const getGraphQLApiUrl = () => config().apiUrl + '/graphql'

export const concatSlots = (slots: Pick<SlotDefinition, 'possibleSlots'>[]) =>
  slots &&
  slots.reduce<number[]>((acc, slot) => {
    acc.push(...slot.possibleSlots)
    return acc
  }, [])

export const secondsToMinutes = (seconds: number) => {
  const m = Math.floor(seconds / 60)
  const s = (seconds % 60).toString().padStart(2, '0')
  return m + ':' + s
}

export const toSlug = (param: string) => slugify(param)

export const dailySlotsGenerator = (start = 480, end = 1320, offset = 15) => {
  const slots = []

  for (let i = start; i <= end; i += offset) {
    slots.push(i)
  }

  return slots
}

export const isDateToday = (date?: dayjs.Dayjs) => {
  return dayjs().isSame(date, 'day')
}

export const sum = (arr: number[]) => arr.reduce((a: number, b: number) => a + b)

type Logo = { centered: string; left: string }

export const getLogos = (festivalCode: FestivalCodesEnum | 'club' = 'club'): Logo => {
  const festivalLogos = getClubConfig().logos

  const logoConfig = festivalLogos[festivalCode] || festivalLogos.club
  return logoConfig as Logo
}
