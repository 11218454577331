import { ComponentProps } from 'react'

import dayjs from 'dayjs'
import { GetServerSidePropsResult } from 'next/types'

import { ApolloClient } from '@apollo/client'

import { globalBoostrapPropsWrapper } from '~/utils/globalBootstrap'
import { dayjsToString } from '~/utils/graphqlDataFormatters'
import { isDaily } from '~/utils/isReservableType'

import ChefsMenuPageView from '~/components/ChefsMenu/ChefsMenuPage/ChefsMenuPageView'
import { paths } from '~/constants'
import {
  ReservableEnum,
  SingleReservableExtraDocument,
  SingleReservableExtraQuery,
  SingleReservableExtraQueryVariables,
  SlotsUsageDocument,
  SlotsUsageQuery,
  SlotsUsageQueryVariables,
} from '~/generated/graphql'
import { smallModalPropsTemplate } from '~/shared/molecules/Modal'

export const slotSelectModalProps = {
  ...smallModalPropsTemplate,
  fillScreenOnMobile: true,
  closeIconPosition: 'outside' as const,
  closeOnEscapeKeyClick: true,
  centerVerticallyOnMobile: false,
}

export const getReservableExtraServerSideProps = async <AdditionalInformation extends Record<string, unknown>>({
  reservableExtraId,
  apolloClient,
  additionalProps,
}: {
  reservableExtraId?: string
  apolloClient: ApolloClient<any>
  additionalProps?: AdditionalInformation
}): Promise<GetServerSidePropsResult<ComponentProps<typeof ChefsMenuPageView>>> => {
  if (!reservableExtraId) {
    return {
      redirect: {
        destination: paths.error404,
        permanent: true,
      },
    }
  }

  const {
    data: { reservableExtra },
  } = await apolloClient
    .query<SingleReservableExtraQuery, SingleReservableExtraQueryVariables>({
      query: SingleReservableExtraDocument,
      variables: { id: reservableExtraId },
    })
    .then(result => {
      apolloClient.cache.writeQuery({
        query: SingleReservableExtraDocument,
        data: result.data,
      })
      return result
    })

  const dailyId = reservableExtra.restaurant.reservables.find(isDaily)?.id

  let initialSlotsData = null

  if (dailyId) {
    initialSlotsData = await apolloClient
      .query<SlotsUsageQuery, SlotsUsageQueryVariables>({
        query: SlotsUsageDocument,
        variables: {
          dates: {
            startsOn: dayjsToString(dayjs.parseZone().clone().startOf('month')),
            endsOn: dayjsToString(dayjs.parseZone().clone().endOf('month').add(1, 'month')),
          },
          reservables: [{ id: dailyId, type: ReservableEnum.Daily }],
          withReservableExtra: true,
        },
      })
      .then(result => result.data)
      .catch(err => err)
  }

  return globalBoostrapPropsWrapper(
    { apolloClient },
    {
      props: {
        reservableExtra,
        initialSlotsData,
        ...additionalProps,
      },
    }
  )
}
