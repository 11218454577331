import { paths } from '~/constants/paths'

export const NAV_BAR_ROUTES = [
  {
    label: 'Discover',
    icon: 'search',
    href: paths.home,
  },
  {
    label: 'Find a table',
    icon: 'restaurant',
    href: paths.restaurants,
  },
  {
    label: '#ChefsMenu',
    icon: 'chefsmenu',
    href: paths.chefsMenuOffers,
  },
  {
    label: 'Festivals',
    icon: 'festivals',
    href: paths.festivals,
  },
]
