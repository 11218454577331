// @ts-strict-ignore
import Menu from '~/components/Checkout/CheckoutPage/shared/Menu'
import { FullMenuFragment, ReservableExtra, Restaurant, RestaurantUrl, TagFragmentFragment } from '~/generated/graphql'

import ChefsMenuDescription from './ChefsMenuDescription'
import Contact from './Contact'
import Description from './Description'
import Features from './Features'

export const sectionData = {
  restaurant: {
    title: 'Restaurant',
    icon: 'text-align-left',
    content: (chef, establishmentYear, tags, description, isMobile) => (
      <Description {...{ chef, establishmentYear, tags, description, isMobile }} />
    ),
  },
  features: {
    title: 'Features',
    icon: 'restaurant',
  },
  location: {
    title: 'Location and contact details',
    icon: 'location-outline',
  },
  chefsMenuDescription: {
    title: 'Description',
    icon: 'text-align-left',
  },
  menu: {
    title: 'Menu',
    icon: 'book-new',
  },
  restaurantDescription: {
    title: 'Description',
    icon: 'text-align-left',
    content: (chef, establishmentYear, tags, description, isMobile) => (
      <Description {...{ chef, establishmentYear, tags, description, isMobile }} />
    ),
  },
}

interface Content {
  sectionName: keyof typeof sectionData
  restaurant: Pick<Restaurant, 'address' | 'phoneNumber' | 'chef' | 'description' | 'establishmentYear' | 'latitude' | 'longitude'> & {
    tags: TagFragmentFragment[]
    urls?: Pick<RestaurantUrl, 'url' | 'urlType'>[] | null
  }
  reservableExtra?: Pick<ReservableExtra, 'description'> & { menus?: FullMenuFragment[] | null }
  isMobile: boolean
}

export const getContent = ({ sectionName, restaurant, reservableExtra, isMobile }: Content) => {
  const { address, phoneNumber, chef, description, establishmentYear, latitude, longitude, tags, urls } = restaurant || {}
  switch (sectionName) {
    case 'restaurant': {
      return <Description {...{ chef, establishmentYear, tags, description, isMobile }} />
    }
    case 'restaurantDescription': {
      return <Description {...{ chef, establishmentYear, tags, description, isMobile }} />
    }
    case 'chefsMenuDescription': {
      return <ChefsMenuDescription description={reservableExtra?.description} />
    }
    case 'features': {
      return <Features tags={tags} isMobile={isMobile} />
    }
    case 'menu': {
      return reservableExtra?.menus?.map((menu, i) => (
        <div key={menu.id}>
          <Menu menu={menu} index={i} />
        </div>
      ))
    }
    case 'location': {
      return (
        <Contact
          {...{
            latitude,
            longitude,
            address,
            phoneNumber,
            urls,
            isMobile,
          }}
        />
      )
    }
    default: {
      return null
    }
  }
}
