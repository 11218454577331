// @ts-strict-ignore
import { get, isEmpty } from 'lodash'

import { processEnv } from '~/utils/env'

import config from '~/config'
import { ReservationSummaryQuery } from '~/generated/graphql'

import { getFields, getName, getVariant, getVoucher, GTMEvent, GTMEventAction } from './gtag-utils'
import { hasWindow } from './helpers'

export const pushDataLayer = ({ event = GTMEvent.Ecommerce, eventAction, category = GTMEvent.Ecommerce, data }) => {
  config().enableAnalytics &&
    hasWindow() &&
    window?.dataLayer &&
    window?.dataLayer.push({
      event,
      event_action: eventAction,
      [category]: data,
    })
}

interface PushUIEventProps {
  event?: GTMEvent
  eventAction: GTMEventAction
  category?: string
  label?: string
  value?: string | number
  data?: any
}

export const pushUIEvent = ({ event = GTMEvent.UI, eventAction, category = GTMEvent.UI, label, value, data }: PushUIEventProps) => {
  config().enableAnalytics &&
    hasWindow() &&
    window.dataLayer &&
    window.dataLayer.push({
      event,
      event_action: eventAction,
      category,
      label,
      value,
      data,
    })
}

export const runEcommerceDetails = ({ reservableType, reservableData }) => {
  const { name, brand, category, variant, price } = getFields(reservableType, reservableData)

  reservableData &&
    pushDataLayer({
      eventAction: GTMEventAction.ProductDetail,
      data: {
        detail: {
          products: [
            {
              name,
              brand,
              category,
              variant,
              price,
            },
          ],
        },
      },
    })
}

export const runVoucherDetails = festivalData => {
  const voucher = getVoucher(festivalData)
  pushDataLayer({
    eventAction: GTMEventAction.ProductDetail,
    data: {
      detail: {
        products: [voucher],
      },
    },
  })
}
export const runVoucherAdd = (festivalData, quantity) => {
  const voucher = getVoucher(festivalData)
  pushDataLayer({
    eventAction: GTMEventAction.Add,
    data: {
      add: {
        actionField: {
          list: 'Profile',
        },
        products: [{ ...voucher, quantity }],
      },
    },
  })
}

export const runEcommerceAdd = ({ reservableType, reservableData }) => {
  const { name, brand, category, variant, price } = getFields(reservableType, reservableData)
  const quantity = get(reservableData, 'quantity')

  pushDataLayer({
    eventAction: GTMEventAction.Add,
    data: {
      add: {
        actionField: {
          list: 'Profile',
        },
        products: [
          {
            name,
            brand,
            category,
            variant,
            price,
            quantity,
          },
        ],
      },
    },
  })
}

export const runEcommerceCheckout = (reservation, steps, loggedIn) => {
  pushDataLayer({
    eventAction: GTMEventAction.Checkout,
    data: {
      checkout: {
        actionField: {
          step: steps,
          option: loggedIn ? 'logged-in' : 'guest',
        },
        products: [
          {
            name: get(reservation, 'reservable.restaurant.name') || get(reservation, 'reservable.title'),
            price: get(reservation, 'reservable.price') || get(reservation, 'reservable.festivalEdition.price') || 0,
            category: get(reservation, 'reservable.__typename'),
            quantity: reservation.peopleCount,
          },
        ],
      },
    },
  })
}

export const runVoucherCheckout = (festivalData, quantity) => {
  const voucher = getVoucher(festivalData)
  quantity &&
    pushDataLayer({
      eventAction: GTMEventAction.Checkout,
      data: {
        checkout: {
          actionField: {
            step: 1,
            option: 'logged-in',
          },
          products: [{ ...voucher, quantity }],
        },
      },
    })
}

const getType = reservation => {
  const reservableExtraAssignments = get(reservation, 'reservable.reservableExtraAssignments')
  return isEmpty(reservableExtraAssignments) ? get(reservation, 'reservable.__typename') : 'ReservableExtra'
}

export const runEcommercePurchase = (
  reservation: Pick<
    ReservationSummaryQuery['reservation'],
    'reservationCrossSells' | 'reservable' | 'peopleCount' | 'id' | 'lastOrderPrice' | 'reservableExtras' | 'reservableExtraAssignments'
  >
) => {
  const type = getType(reservation)
  const crossSells = reservation.reservationCrossSells
  const name = getName(type, get(reservation, 'reservable'))
  const variant = getVariant(type, get(reservation, 'reservable'))
  const price = get(reservation, 'reservable.price')
  const reservableExtras = get(reservation, 'reservableExtras')
  const reservableExtraAssignments = get(reservation, 'reservableExtraAssignments')

  const mainProduct = {
    name,
    price,
    brand: name,
    category: type,
    variant,
    quantity: get(reservation, 'peopleCount'),
  }
  const products = [mainProduct]
  crossSells.forEach(({ crossSell, quantity }) => {
    products.push({
      name: crossSell.name,
      price: crossSell.price,
      brand: crossSell.name,
      category: crossSell.__typename,
      variant,
      quantity,
    })
  })

  reservableExtraAssignments
    ?.map(({ quantity, reservableExtraId }) => ({ quantity, ...reservableExtras.find(({ id }) => id === reservableExtraId) }))
    ?.forEach(({ quantity, name, price, __typename }) => {
      products.push({
        name,
        price,
        brand: name,
        category: __typename,
        variant,
        quantity,
      })
    })

  pushDataLayer({
    eventAction: GTMEventAction.Purchase,
    data: {
      purchase: {
        actionField: {
          id: reservation.id,
          festivalCode: variant,
          affiliation: variant,
          region: get(reservation, 'reservable.restaurant.region.name'),
          revenue: reservation.lastOrderPrice,
          tax: 0,
          currency: processEnv.CURRENCY,
          coupon: get(reservation, 'discount.code') || '',
        },
        products,
      },
    },
  })
}

export const runVoucherPurchase = voucher => {
  const singlePrice = +get(voucher, 'festivalEdition.price')
  const totalPrice = +voucher.peopleCount * singlePrice

  pushDataLayer({
    eventAction: GTMEventAction.Purchase,
    data: {
      purchase: {
        actionField: {
          id: voucher.id,
          festivalCode: get(voucher, 'festivalEdition.code'),
          affiliation: get(voucher, 'festivalEdition.code'),
          revenue: totalPrice,
          tax: 0,
          currency: processEnv.CURRENCY,
        },
        products: [
          {
            name: 'wczesniejsza rezerwacja',
            price: singlePrice,
            brand: 'Restaurant Club',
            variant: get(voucher, 'festivalEdition.code'),
            category: get(voucher, '__typename'),
            quantity: get(voucher, 'peopleCount'),
          },
        ],
      },
    },
  })
}
