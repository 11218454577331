import { gt as GT } from '~/locale'

import { ClubConfigCopy } from '../../types'

import { voucherTranslation } from './pageTranslate'

const rwReservationStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your reservation...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Booking confirmed!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Reservation cancelled'),
})

const giftStatusMessages = (gt: typeof GT) => ({
  PENDING: gt.tp('OrderStatus', 'Processing your purchase...'),
  SUCCEEDED: gt.tp('OrderStatus', 'Congrats! Your gift purchase is complete!'),
  FAILED: gt.tp('OrderStatus', 'Payment rejected'),
  CANCELED: gt.tp('OrderStatus', 'Gift purchase cancelled'),
})

const beforeYouVisitTitle = (gt: typeof GT) => gt.tp('SuccessPage', 'Read before you visit')

const beforeYouVisitDefaultDescription = (gt: typeof GT) =>
  gt.tp(
    'SuccessPage',
    // eslint-disable-next-line max-len
    'Be eco! Don\'t print the confirmation - at the restaurant, the confirmation on your phone will be enough. Please be on time :). Punctuality gives everyone an equal opportunity to enjoy their dream restaurant experience. After the visit, log in to your account, select "Your reservations" and "Review". The restaurant counts on your honest opinion!'
  )

export const copy: ClubConfigCopy = {
  voucher: voucherTranslation,
  rwReservationStatusMessages,
  giftStatusMessages,
  beforeYouVisitTitle,
  beforeYouVisitDefaultDescription,
}
