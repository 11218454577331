import { getClubConfig } from '~/config/clubConfig'
import { FestivalSalesOffer } from '~/types/festival'

import { getVoucherEventsListingBanners } from './events-listing'

export type VoucherImagesFunction<T> = (festivalSalesOffer: FestivalSalesOffer) => T

function getVoucherSalesOffer<T>(getImagesObject: VoucherImagesFunction<T>) {
  return () => getImagesObject(getClubConfig().festivalSalesOffer)
}

export const voucherImages = {
  eventsListing: getVoucherSalesOffer(getVoucherEventsListingBanners),
}
