import { FestivalSalesOffer } from '~/types/festival'

import { GetVoucherCopy } from '..'

import { getWRCopy } from './WR'

export type VoucherSuccessScreenCopy = {
  succeeded: string
  congratulations: (festivalName: string) => string
  title: string
  description: string
}

// /success-screen page
export const getVoucherSuccessScreenCopy: GetVoucherCopy<VoucherSuccessScreenCopy> = festivalSalesOffer => {
  switch (festivalSalesOffer) {
    case FestivalSalesOffer.WR:
    default:
      return getWRCopy
  }
}
