// @ts-strict-ignore
import { useMediaQuery } from 'react-responsive'

import { Form, Formik } from 'formik'
import Image from 'next/image'

import { mediaQueryIsMobile, UniversalContainer } from '~/theme/utils/grid'
import { Heading3, Heading4, Heading6 } from '~/theme/utils/typography'
import { formatErrorMessages } from '~/utils/formatErrorMessages'
import { getLogos } from '~/utils/helpers'
import useMobileNavigation from '~/utils/hooks/useMobileNavigation'

import { getClubConfig } from '~/config/clubConfig'
import config from '~/config/index'
import { paths } from '~/constants'
import { useNewsletterSignUpMutation } from '~/generated/graphql'
import { gt } from '~/locale'
import Collapse from '~/shared/atoms/Collapse'
import CollapsibleLabel from '~/shared/atoms/CollapsibleLabel'
import Input from '~/shared/atoms/Input'
import Link from '~/shared/atoms/Link'
import { useToast } from '~/shared/atoms/Toast'
import { VisibleIfFeatureFlag } from '~/shared/atoms/VisibleIfFeatureFlag'

import Awards from './Awards'
import {
  AwardsContainer,
  Benefit,
  BenefitIcon,
  Benefits,
  BenefitsTitle,
  BottomLinks,
  Consent,
  Copyright,
  FooterContainer,
  FooterPartner,
  IconButton,
  JoinButton,
  JoinHeading,
  JoinInputContainer,
  JoinTheClub,
  LinkItem,
  LinkMap,
  Logo,
  Partners,
  SocialMedia,
  ToastContainer,
  Wrapper,
} from './FooterShards'
import { benefitsData, joinTheClubSchema, linkData } from './FooterUtils'

const Footer: React.FC = () => {
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const [signUp] = useNewsletterSignUpMutation()
  const isMobileNavigation = useMobileNavigation()
  const clubConfig = getClubConfig()

  const toast = useToast()
  const { tp } = gt
  const handleSignUp = (email, resetForm, setSubmitting) => {
    signUp({
      variables: { email: email },
      onCompleted: () => {
        toast.add({
          type: 'success',
          message: gt.tp('ConfirmationMessage', 'Email sent!'),
          containerSelector: '#FooterToastsContainer',
          size: 'regular',
        })
        resetForm()
        setSubmitting(false)
      },
      onError: err =>
        formatErrorMessages(err)?.map(error => {
          toast.add({
            message: error,
            type: 'error',
            containerSelector: '#FooterToastsContainer',
            size: 'regular',
          })
          return setSubmitting(false)
        }),
    })
  }
  const LinkSection = isMobile ? Collapse : 'section'
  return (
    <FooterContainer>
      {clubConfig.footerAwardsEnabled && (
        <AwardsContainer>
          <UniversalContainer>
            <Awards />
          </UniversalContainer>
        </AwardsContainer>
      )}
      <UniversalContainer>
        <Wrapper>
          <Logo src={getLogos().centered} alt='RestaurantClub Logo' />
          <LinkMap>
            {linkData.map(section => {
              const collapseProps = isMobile ? { title: tp('Footer', section.title) } : {}
              return (
                <LinkSection key={section.title} size='regular' {...collapseProps}>
                  {!isMobile && <Heading6 fontWeight='semiBold'>{tp('Footer', section.title)}</Heading6>}
                  {section.links.map(link => {
                    return link.href ? (
                      <VisibleIfFeatureFlag name={link.enabledForFeatureFlag} hasValue={true} key={link.label}>
                        <Link href={link.href}>
                          <LinkItem onClick={link?.onClick ?? (() => null)}>{tp('Footer', link.label)}</LinkItem>
                        </Link>
                      </VisibleIfFeatureFlag>
                    ) : (
                      <VisibleIfFeatureFlag name={link.enabledForFeatureFlag} hasValue={true} key={link.label}>
                        <LinkItem key={link.label} onClick={link.onClick}>
                          {tp('Footer', link.label)}
                        </LinkItem>
                      </VisibleIfFeatureFlag>
                    )
                  })}
                </LinkSection>
              )
            })}
          </LinkMap>
          <JoinTheClub>
            <JoinHeading as={isMobile ? Heading4 : Heading3}>{tp('Footer', 'Join the Club!')}</JoinHeading>
            {isMobile && <Heading6 fontWeight='medium'>{tp('Footer', 'Get exclusive discounts and access to exceptional gifts')}</Heading6>}
            <ToastContainer id='FooterToastsContainer' />
            <Formik
              initialValues={{ email: '' }}
              validationSchema={joinTheClubSchema}
              onSubmit={(values, { resetForm, setSubmitting }) => {
                handleSignUp(values.email, resetForm, setSubmitting)
              }}>
              {({ values, errors, handleSubmit, isSubmitting, handleChange }) => (
                <Form>
                  <JoinInputContainer>
                    <Input
                      error={!!errors.email}
                      errorMessage={errors.email}
                      onChange={handleChange}
                      name='email'
                      width='full'
                      height='normal'
                      value={values.email}
                      placeholder={tp('Footer', 'Your email address...')}
                    />
                    <JoinButton loading={isSubmitting} onClick={handleSubmit} width={'full'} color='orange' label={tp('Footer', 'Join')} />
                  </JoinInputContainer>
                  <Consent>
                    <CollapsibleLabel
                      label={tp(
                        'Footer',
                        'By pressing „Join” you accept collecting and benefiting from DISCOUNT CODES, be notified of Festivals, obtain eBook “RESTAURANTS’ RECIPES” (price PLN 78), therefore I consent to the sending of the commercial information'
                      )}
                      extendedLabel={tp(
                        'Footer',
                        'to me by Restaurant Club sp. z o. o. to the contact details I provided and the use of these data for direct marketing purposes via electronic terminal devices (e-mail, online social networking sites, phone number provided by me - SMS, telephone contact).'
                      )}
                    />
                    <Benefits>
                      <BenefitsTitle>{tp('Footer', 'You will gain:')}</BenefitsTitle>
                      {benefitsData.map(text => (
                        <Benefit key={text}>
                          <BenefitIcon icon='check' />
                          {tp('Footer', text)}
                        </Benefit>
                      ))}
                    </Benefits>
                  </Consent>
                </Form>
              )}
            </Formik>
          </JoinTheClub>
          <Partners>
            <FooterPartner>
              <p>{tp('Footer', 'Payments partner')}</p>
              <a href='https://poland.payu.com/' target='_blank' rel='noreferrer'>
                <Image src='/assets/logo-payu-partner.svg' width={96} height={34} alt='Logo PayU' />
              </a>
            </FooterPartner>
            <FooterPartner>
              <p>{tp('Footer', 'Technology partner')}</p>
              <Image src='/assets/partners/sales-manago-logo.svg' width={131} height={20} alt='Logo Sales Manago' />
            </FooterPartner>
          </Partners>

          <SocialMedia>
            <Link href={config().fb}>
              <IconButton icon='facebook' />
            </Link>
            <Link href={config().instagram}>
              <IconButton icon='instagram' />
            </Link>
            {/* <Link href={''}>
          <IconButton icon='twitter' />
        </Link> */}
            <Link href={config().linkedin}>
              <IconButton icon='linkedin' />
            </Link>
          </SocialMedia>
          {isMobile ? (
            <BottomLinks addPadding={isMobileNavigation}>
              <Link href={paths.privacyPolicy}>
                <LinkItem> {tp('Footer', 'Privacy Policy')} </LinkItem>
              </Link>
              <Link href={paths.regulations}>
                <LinkItem> {tp('Footer', 'Terms and Conditions')} </LinkItem>
              </Link>
              <Link href={paths.home}>
                <LinkItem> {tp('Footer', 'Contact')}</LinkItem>
              </Link>
              <LinkItem onClick={() => window.OneTrust.ToggleInfoDisplay()}>{tp('Footer', 'Cookies')}</LinkItem>
            </BottomLinks>
          ) : (
            clubConfig.showFooterCopyright && (
              <Copyright>
                {tp(
                  'Footer',
                  `Copyright © 2023 Restaurant Club Sp. z o. o., Aleje Ujazdowskie 24 lok. 23a, 00-478 Warszawa,
info@restaurantclub.pl, +48 22 113 43 00`
                )}
              </Copyright>
            )
          )}
        </Wrapper>
      </UniversalContainer>
    </FooterContainer>
  )
}

export default Footer
