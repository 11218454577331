import { ReactNode } from 'react'

import { ClubConfig, getClubConfig } from '~/config/clubConfig'

type Props<Flag extends keyof ClubConfig> = {
  children: ReactNode
  name?: Flag
  hasValue?: ClubConfig[Flag]
}

export const VisibleIfFeatureFlag = <Flag extends keyof ClubConfig>({ name, hasValue, children }: Props<Flag>) => {
  const clubConfig = getClubConfig()
  const isVisible = typeof name === 'undefined' || clubConfig[name] === hasValue
  return isVisible ? <>{children}</> : null
}
