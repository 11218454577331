import styled from '@emotion/styled'

import { Heading6 } from '~/theme/utils/typography'

import { BasicIcon } from '~/shared/atoms/Icon'

export const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 5.25rem;
  background: ${({ theme }) => theme.colors.gray[0]};
  border-top: 1px solid ${({ theme }) => theme.colors.gray[200]};
  position: fixed;
  bottom: 0;
  z-index: 1000;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 1rem;
`

export const NavButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 0.875rem;
  width: 4.75rem;
  height: 4.25rem;
`

export const NavButtonIcon = styled(BasicIcon)<{ active: boolean }>`
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: 0.25rem;
  filter: ${({ active, theme }) => (active ? theme.filters.orange[500] : theme.filters.gray[700])};
`

export const NavButtonLabel = styled(Heading6)<{ active: boolean }>`
  font-weight: 500;
  font-size: 0.625rem;
  filter: ${({ active, theme }) => (active ? theme.filters.gray[1000] : theme.filters.gray[700])};
  white-space: nowrap;
`
